
  import Events from '@/events/Events';
  import DialogsBaseSection from '@/components/sections/DialogsBaseSection.vue';
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    components: {
      DialogsBaseSection,
      VerifyUserAlert: () => import('@/components/VerifyUserAlert'),
    },
    mixins: [ScreenSize],
    props: {
      jobPosting: {
        type: Object,
        required: false,
        default: () => {},
      },
      searchRequest: {
        type: String,
        required: false,
        default: '',
      },
      customTitle: {
        type: String,
        required: false,
        default: '',
      },
      organizationId: {
        type: String,
        required: false,
        default: '',
      },
      typesenseStorageKey: {
        type: String,
        required: true,
        default: 'TypesenseParams',
      },
      alertType: {
        type: String,
        required: false,
        default: 'listing',
      },
    },
    data() {
      return {
        alertTitle: '',
        searchUrl: '',
        urlAlertTitle: '',
        dialog: false,
        jobAlertDialogSection: {
          title: this.$t('job_alert.dialog_title'),
          intro_text: this.$t('job_alert.dialog_intro_text'),
        },
        email: '',
        title: '',
        terms: '',
        gdpr: '',
        interval: {
          value: 3600,
          text: 'interval.hourly',
        },
        intervalItems: [
          {
            value: 3600,
            text: 'interval.hourly',
          },
          {
            value: 14400,
            text: 'interval.four_hourly',
          },
          {
            value: 86400,
            text: 'interval.daily',
          },
          {
            value: 604800,
            text: 'interval.weekly',
          },
        ],
      };
    },
    watch: {
      '$route.query': function () {
        this.setUpTitle();
      },
      searchRequest: function () {
        this.setUpTitle();
      },
    },
    created() {
      this.setUpTitle();
    },
    methods: {
      setUpTitle() {
        if (this.jobPosting) {
          this.alertTitle = 'Jobs ähnlich zu "' + this.jobPosting.name + '"';
          this.searchUrl = 'activity[]=' + this.jobPosting.activity_keys;
          if (
            this.$auth.loggedIn &&
            this.$auth.user.candidateProfile.addresses
          ) {
            for (
              let i = 0;
              i <
              Object.keys(this.$auth.user.candidateProfile.addresses).length;
              i++
            ) {
              if (
                this.$auth.user.candidateProfile.addresses[i] &&
                this.$auth.user.candidateProfile.addresses[i].geoCluster
              ) {
                this.searchUrl =
                  this.searchUrl +
                  '&geoCluster=' +
                  this.$auth.user.candidateProfile.addresses[i].geoCluster;
                break;
              }
            }
          }
        } else {
          this.searchUrl = this.searchRequest;
        }
        if (this.$route.query.whereInput && this.$route.query.search) {
          this.urlAlertTitle =
            'Suche nach "' +
            this.$route.query.search +
            '" in "' +
            this.$route.query.whereInput +
            '"';
        } else if (this.$route.query.whereInput && !this.$route.query.search) {
          this.urlAlertTitle =
            'Suche in "' + this.$route.query.whereInput + '"';
        } else if (!this.$route.query.whereInput && this.$route.query.search) {
          this.urlAlertTitle = 'Suche nach "' + this.$route.query.search + '"';
        } else {
          this.urlAlertTitle =
            this.customTitle != ''
              ? this.customTitle
              : 'Suche vom ' +
                new Date().toLocaleString('de-DE', {dateStyle: 'medium'});
        }
        this.title = this.alertTitle ? this.alertTitle : this.urlAlertTitle;
      },
      closeDialog() {
        this.dialog = false;
      },
      async createJobAlert() {
        let props = {};
        const params = new URLSearchParams(this.searchUrl);
        params.set('created', this.interval.value);

        let typesenseParams = await this.$localforage.getItem(
          this.typesenseStorageKey
        );
        if (this.organizationId) {
          typesenseParams = {
            q: '*',
            filter_by: 'organization_profile_id:=' + this.organizationId,
          };
        }

        if (this.$auth.loggedIn) {
          props = {
            title: this.title,
            interval: this.interval.text,
            created: this.interval.value,
            searchRequest: this.searchUrl ? params.toString() : '',
            candidateAccountId: this.$auth.user.id,
            typesense: typesenseParams,
          };
        } else {
          props = {
            title: this.title,
            interval: this.interval.text,
            created: this.interval.value,
            searchRequest: this.searchUrl ? params.toString() : '',
            email: this.email,
            typesense: typesenseParams,
          };
        }

        const trackingObject = {};
        trackingObject.searchParameters = this.searchUrl ? this.searchUrl : '';
        trackingObject.email = props.email ? props.email : '';

        await this.$store
          .dispatch('api/jobAlerts/createJobAlert', props)
          .then(() => {
            this.$notifications.showNotification({
              message: this.$t('job_alert.dialog_notification_success'),
              color: 'success',
              timeout: 3000,
            });
            this.$emitWithClientData(Events.JobAlertSubscribed, trackingObject);
            this.email = '';
            this.title = '';
            this.terms = '';
            this.gdpr = '';
            this.dialog = false;
            this.interval = {value: 3600, text: 'interval.hourly'};
            this.$refs.form.reset();
          })
          .catch((e) => {
            if (e.response.status != 204 && e.response.status === 500) {
              this.$notifications.showNotification({
                message: this.$t('job_alert.dialog_notification_empty_filter'),
                color: 'error',
                timeout: 3000,
              });
            }
            if (
              e.response &&
              e.response.data &&
              e.response.data.violations &&
              e.response.data.violations.length > 0 &&
              e.response.status != 204 &&
              e.response.status !== 500
            ) {
              e.response.data.violations.map((violation) => {
                if (violation.code === 'f6143155-e9c5-d54f-7af4-8d9cc28c09c7') {
                  this.$notifications.showNotification({
                    message: this.$t(
                      'job_alert.dialog_notification_invalid_mautic_id'
                    ),
                    color: 'error',
                    timeout: 3000,
                  });
                }
              });
            }
          });
      },
    },
  };
